import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";

import logo from "../../assets/logo-3d.png";

export default function Sidbar({ data }) {
  const [solicitatations, setSolicitations] = useState([]);

  useEffect(() => {
    async function loadSolicitations() {
      const response = await api.get("listar_pedidos");
      setSolicitations(response.data);
    }
    loadSolicitations();
  });

  return (
    <div
      id="sidebar-wrapper"
      className="gradient-royal"
      data-simplebar=""
      data-simplebar-auto-hide="true"
    >
      <div
        className="brand-logo"
        style={{
          position: "relative",
          padding: 10,
          paddingLeft: 50,
          paddingBottom: 125,
          boxSizing: "border-box",
        }}
      >
        <Link to="/">
          <img
            style={{ width: "50%" }}
            src={logo}
            // className="logo-icon"
            alt="Niteroi Gas"
          />
          {/* <h5 className="logo-text">Nacional Gas</h5> */}
        </Link>
      </div>
      <ul className="sidebar-menu do-nicescrol">
        <li className="sidebar-header">GESTÃO DE DADOS</li>
        <li>
          <Link to={"/"} className="waves-effect">
            <i className="icon-home"></i> <span>Painel de Controle</span>
          </Link>
        </li>
        <li>
          <Link to={"/dashboard/solicitations"} className="waves-effect">
            <i className="fal fa-address-card"></i> <span>Pedidos</span>
            <small className="badge float-right badge-primary">
              {
                solicitatations.filter(
                  (solic) =>
                    !solic.entregador &&
                    solic.status !== "entregar" &&
                    solic.cancel == null
                ).length
              }
            </small>
          </Link>
        </li>
        <li>
          <Link to="/dashboard/address" className="waves-effect">
            <i className="fal fa-users"></i> <span>Usuários</span>
          </Link>
        </li>
        {/* Entregador */}
        <li className="sidebar-header">ENTREGADORES</li>
        <li>
          <Link to={"/dashboard/newDeliveryman"} className="waves-effect">
            <i className="fal fa-motorcycle"></i>{" "}
            <span>Adicionar Entregadores</span>
          </Link>
        </li>

        <li className="sidebar-header">BAIRROS</li>
        <li>
          <Link to={"/dashboard/bairros"} className="waves-effect">
            <i className="fa fa-map-marker"></i> <span>Bairros & CEPs</span>
          </Link>
        </li>
        <li className="sidebar-header">PRODUTOS</li>
        <li>
          <Link to={"/dashboard/produtos"} className="waves-effect">
            <i className="far fa-box"></i> <span>Produtos</span>
          </Link>
        </li>
        <li className="sidebar-header">FORMAS DE PAGAMENTO</li>
     
        <li>
          <Link to={"/dashboard/formapagamento"} className="waves-effect">
            <i className="far fa-credit-card"></i> <span>Formas de Pagamento</span>
          </Link>
        </li>
      
      </ul>
    </div>
  );
}
