import React, { useState, useEffect } from 'react';
import api from '../../services/api';

export default function Card() {
  const [users, setUsers] = useState([]);
  const [address, setAddress] = useState([]);
  const [solicitatations, setSoliciatations] = useState([]);
  const [entregador, setEntregador] = useState([]);


  useEffect(() => {
    async function loadUser() {
      const response = await api.get('user/');
      const userData = response.data;
      const users = userData.filter((value) => value.provider == false);
      setUsers(users);
    }
    async function loadAddress() {
      const response = await api.get('enderecos/');
      setAddress(response.data);
    }
    async function loadSolicitations() {
      const response = await api.get('listar_pedidos/');
      setSoliciatations(response.data);
    }
    async function loadEntregador() {
      const response = await api.get('listar_entregadores');
      setEntregador(response.data);
    }
    loadAddress();
    loadSolicitations();
    loadUser();
    loadEntregador();
  }, [users.length]);
  return (
    <>
    {/* Total de Usuarios */}
      <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
        <div className="card bg-primary shadow-primary">
          <div className="card-body p-4">
            <div className="media">
            <div className="media-body text-left">
              <h4 className="text-white">{users.length}</h4>
              <span className="text-white">Total Usuários</span>
            </div>
            <div className="align-self-center w-icon"><i className="icon-user text-white"></i></div>
          </div>
          </div>
        </div>
      </div>
      
    {/* Total de Endereços */}
      <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
        <div className="card bg-danger shadow-danger">
          <div className="card-body p-4">
            <div className="media">
            <div className="media-body text-left">
              <h4 className="text-white">{address.length}</h4>
              <span className="text-white">Total Endereços</span>
            </div>
            <div className="align-self-center w-icon"><i className="icon-directions text-white"></i></div>
          </div>
          </div>
        </div>
      </div>
    
      {/* Total de Entregas */}
      <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div className="card bg-success shadow-success">
            <div className="card-body p-4">
              <div className="media">
              <div className="media-body text-left">
                <h4 className="text-white">{solicitatations.length}</h4>
                <span className="text-white">Total Pedidos</span>
              </div>
              <div className="align-self-center w-icon"><i className="icon-basket-loaded text-white"></i></div>
            </div>
            </div>
          </div>
        </div>
        
        {/* Entregadores */}
        <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div className="card bg-warning shadow-warning">
            <div className="card-body p-4">
              <div className="media">
              <div className="media-body text-left">
                <h4 className="text-white">{entregador.length}</h4>
                <span className="text-white">Entregadores</span>
              </div>
              <div className="align-self-center w-icon"><i className="icon-people text-white"></i></div>
            </div>
            </div>
          </div>
        </div>
    </>
  );
}
