import axios from "axios";

const api = axios.create({
  //baseURL: "https://backend-heroku.herokuapp.com",
  baseURL: "https://backend-hk-fa4d2e180123.herokuapp.com",
  //baseURL: "https://painelniteroigas.com.br/api",
 // baseURL: 'http://localhost:4000/',
});

export default api;
