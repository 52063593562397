import React from 'react';
import axios from 'axios';
import api from './api';

export async function loadUser() {
  const response = await api.get('user/');
  return response;
}
export async function loadAddress() {
  const response = await api.get('enderecos/');
  return response;
}
export async function loadSolicitations() {
  const response = await api.get('listar_pedidos/');
  return response;
}

export const getStoreAvailable = async () => {
  try {
    const { data } = await api.get('/store-live/6481d78d90ae400edfde1e95'); 
    return data;
  } catch (error) {
    console.log('Erro', error);
  }
};
export const updateStoreAvailable = async (storeData) => {
  try {
    const { data } = await api.put('store-live', { ...storeData });
    return data;
  } catch (error) {
    console.log(error);
  }
};
