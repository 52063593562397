import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import React from "react";

import { isAuthenticated } from "./config/auth";

import Login from "./pages/login/index";
import Solicitation from "./pages/dashboard/show/showSolicitation";
import UserAddress from "./pages/dashboard/show/showUsers";
import NewDeliveryman from "./pages/dashboard/store/cadDeliveryman";
import Dashboard from "./pages/dashboard/index";
import Bairros from "./pages/BairrosCeps/index";
import Produtos from "./pages/Produtos";
import FormaPagamento from "./pages/FormaPagamento";

// politica de privacidade
import Privacy from './pages/Privacy/index';
import Termos from './pages/Privacy/termos';

import { AuthProvider } from "./auth/AuthContext";
import { PrivateRoutes } from "./auth/PrivateRoutes";

export default function Routes() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route path="/dashboard/newAddress" component={NewAddress} /> */}
          {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
          <Route path="/privacidade" component={Privacy} />
          <Route path="/termos" component={Termos} />
          <Route path="/login" component={Login} />
          <PrivateRoutes path="/" exact component={Dashboard} />
          <PrivateRoutes path="/dashboard/address" component={UserAddress} />
          <PrivateRoutes
            path="/dashboard/solicitations"
            component={Solicitation}
          />
          <PrivateRoutes
            path="/dashboard/newDeliveryman"
            component={NewDeliveryman}
          />
          <PrivateRoutes path="/dashboard/bairros" component={Bairros} />
          <PrivateRoutes path="/dashboard/produtos" component={Produtos} />
          <PrivateRoutes path="/dashboard/formapagamento" component={FormaPagamento}/>

          {/* <Route path="/painel/user" component={userDashboard} />
        <Route path="/painel/address" component={ShowAddress} /> */}
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}
