/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { authConfig } from '../../auth/config';
import api from '../../services/api';
import { useMutation, useQuery } from 'react-query';

import logo from '../../assets/logo-3d.png';

import './style.css';
import {
  getStoreAvailable,
  updateStoreAvailable
} from '../../services/endpoints';

function logout() {
  authConfig
    .auth()
    .signOut()
    .then(function () {
      // Sign-out successful.
      console.log(`🔴 Conta desconectada`);
    })
    .catch(function (error) {
      // An error happened.
      console.log(`🔴 Falha ao desconectar conta`);
    });
}

function handleSidbar() {
  console.log(1);
  var element = document.querySelector('#sidebar-wrapper');
  var content = document.querySelector('.content-wrapper');
  var icon = document.querySelector('.menu-icon');

  if (element.style.left === '0px') {
    element.style.left = '-240px';
    content.style.marginLeft = '0px';
    icon.style.marginLeft = '0px';
  } else {
    element.style.left = '0px';
    content.style.marginLeft = '240px';
    icon.style.marginLeft = '240px';
  }
}

export default function header() {
  const [storeAvailable, setStoreAvailabe] = React.useState(false);

  useQuery('getStoreAvailable', {
    queryFn: () => getStoreAvailable(),
    onSuccess: (data) => {
      console.log(data?.isOpen);
      setStoreAvailabe(data?.isOpen);
    },
    onError: (err) => console.log(err)
  });

  const { mutate: mutateFazerPedido, isLoading: loadingStoreAvailable } =
    useMutation('updateStoreAvailable', {
      mutationFn: (data) => updateStoreAvailable(data),
      onSuccess: (data) => {
        setStoreAvailabe(data?.isOpen);
      },
      onError: (err) => console.log(err)
    });

  const handleStoreAvailable = () => {
    mutateFazerPedido({
      _id: '6481d78d90ae400edfde1e95',
      isOpen: !storeAvailable
    });
  };

  return (
    <header class='topbar-nav'>
      <nav class='navbar navbar-expand fixed-top gradient-ibiza'>
        <ul class='navbar-nav mr-auto align-items-center'>
          {/* Togle Menu */}
          <li class='nav-item'>
            <a class='nav-link toggle-menu' onClick={() => handleSidbar()}>
              <i class='icon-menu menu-icon'></i>
            </a>
          </li>
          {/* Pesquisar */}
        </ul>

        <ul class='navbar-nav align-items-right right-nav-link'>
          {/* Botão Liga/Desliga */}
          <li class='nav-item'>
            {loadingStoreAvailable ? (
              <img
                width={20}
                style={{ marginTop: 20 }}
                height={20}
                src='https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif'
              />
            ) : (
              <>
                <a
                  class='nav-link dropdown-toggle dropdown-toggle-nocaret waves-effect droprow'
                  data-toggle='dropdown'
                  href='javascript:void();'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    class='toggle-store-available'
                    style={{
                      backgroundColor: storeAvailable ? '#15CA20' : '#dc3545'
                    }}
                  />
                  <i class='bi bi-shop'></i>
                </a>
                <div class='dropdown-menu dropdown-menu-right animated fadeIn'>
                  <ul class='list-group list-group-flush'>
                    <li class='list-group-item'>Abrir / Fechar Loja</li>
                    <li class='list-group-item'>
                      <div class='media'>
                        {/* <div class="avatar"><img class="align-self-start mr-3" src="assets/images/avatars/avatar-4.png" alt="user avatar"></div> */}
                        <div class='media-body'>
                          <h6 class='mt-0 msg-title'>
                            Aperte no botão abaixo para abrir / fechar loja
                          </h6>
                          <p>
                            <label class='switch'>
                              <input
                                type='checkbox'
                                checked={storeAvailable}
                                onChange={handleStoreAvailable}
                              />
                              <span class='slider round'></span>
                            </label>
                          </p>
                        </div>
                      </div>
                    </li>

                    <b
                      class={`btn ${
                        storeAvailable ? 'btn-success' : 'btn-danger'
                      }`}
                    >
                      Loja está {storeAvailable ? 'ABERTA' : 'FECHADA'}
                      <i class='bi bi-door-closed-fill'></i>
                    </b>
                  </ul>
                </div>
              </>
            )}
          </li>
          {/* Menu Logout */}
          <li class='nav-item'>
            <a
              class='nav-link dropdown-toggle dropdown-toggle-nocaret'
              data-toggle='dropdown'
              href='#'
            >
              <span class='user-profile'>
                <img
                  class='align-self-start mr-3'
                  src={logo}
                  alt='user avatar'
                />
              </span>
            </a>
            <ul class='dropdown-menu dropdown-menu-left animated fadeIn'>
              <li class='dropdown-item user-details'>
                <a href='javaScript:void();'>
                  <div class='media'>
                    <div class='avatar'>
                      <img
                        class='align-self-start mr-3'
                        src={logo}
                        alt='user avatar'
                      />
                    </div>
                    <div class='media-body'>
                      <h6 class='mt-2 user-title'>Niteroi Gas</h6>
                      <p class='user-subtitle'>niteroigas01@gmail.com</p>
                    </div>
                  </div>
                </a>
              </li>
              <li class='dropdown-divider'></li>
              {/* <li class="dropdown-item">
                <i class="icon-envelope mr-2"></i> Sobre
              </li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-item">
                <i class="icon-settings mr-2"></i> Configuraçõess
              </li> */}

              <li class='dropdown-divider'></li>
              <button
                style={{ background: 'none', border: 'none', width: '100%' }}
                onClick={logout}
              >
                <li class='dropdown-item'>
                  <i class='icon-power mr-2'></i> Sair
                </li>
              </button>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}
