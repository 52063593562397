/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Header from '../../components/header';
import Sidbar from '../../components/sidbar';

export default function index() {
  const [produtos, setProdutos] = useState([]);

  const [produtoModalEditar, setProdutoModalEditar] = useState();
  const [nomeProdutoEditar, setNomeProdutoEditar] = useState('');
  const [precoProdutoEditar, setPrecoProdutoEditar] = useState(0);
  const [paymentCredit, setPaymentCredit] = useState(0);
  const [paymentDebit, setPaymentDebit] = useState(0);
  const [paymentCash, setPaymentCash] = useState(0);

  const openModal = (value) => {
    setProdutoModalEditar(value);
    setNomeProdutoEditar(value?.nome_produto);
    setPrecoProdutoEditar(value?.preco_produto);
    setPaymentCredit(value.payment_type?.[0]?.taxajuros);
    setPaymentDebit(value.payment_type?.[1]?.taxajuros);
    setPaymentCash(value.payment_type?.[2]?.taxajuros);
  };

  function editarProduto() {
    if (nomeProdutoEditar && precoProdutoEditar) {
      api({
        method: 'PUT',
        url: '/atualiza_produto',
        headers: { _id: produtoModalEditar._id },
        data: {
          nome_produto: nomeProdutoEditar,
          preco_produto: precoProdutoEditar,
          payment_type: [
            {
              nomeForma: 'Cartão de Crédito',
              taxajuros: paymentCredit
            },
            {
              nomeForma: 'Cartão de Débito',
              taxajuros: paymentDebit
            },
            {
              nomeForma: 'Espécie',
              taxajuros: paymentCash
            }
          ]
        }
      }).then((res) => {
        document.location.reload();
        // setBairros([...bairros, res.data]);
      });
    } else {
      alert('Nenhum dado foi modificado');
    }
  }

  useEffect(() => {
    // Buscar Produtos
    api({
      method: 'GET',
      url: '/produtos'
    })
      .then((res) => {
        setProdutos(res.data);
      })
      .catch(() => {});
  }, []);
  return (
    <div id='wrapper'>
      <Sidbar />
      <Header />
      <div className='content-wrapper'>
        {/* <!-- Button trigger modal --> */}
        <button
          type='button'
          class='btn btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#exampleModal'
          data-bs-whatever='@getbootstrap'
        >
          <i className='fa fa-plus'></i>
        </button>

        {/* <!-- 🟠 Modal Editar --> */}
        <div
          class='modal fade'
          id='editarModal'
          tabindex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div class='modal-dialog'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='exampleModalLabel'>
                  Editar Produto
                </h5>
                <button
                  type='button'
                  class='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => setProdutoModalEditar()}
                ></button>
              </div>
              {/* Body Modal */}
              <div class='modal-body'>
                <form>
                  <label htmlFor='name'>Nome</label>
                  <div class='input-group mb-3'>
                    <span class='input-group-text' id='basic-addon1'>
                      <i class='fa fa-user' aria-hidden='true' />
                    </span>
                    <input
                      onChange={(e) => setNomeProdutoEditar(e.target.value)}
                      value={nomeProdutoEditar}
                      type='text'
                      id='name'
                      class='form-control'
                      defaultValue={produtoModalEditar?.nome_produto}
                      aria-describedby='basic-addon1'
                    />
                  </div>

                  <label htmlFor='preco'>Preço</label>
                  <div class='input-group mb-3'>
                    <span class='input-group-text' id='basic-addon1'>
                      <i class='fa fa-usd' aria-hidden='true' />
                    </span>
                    <input
                      onChange={(e) => setPrecoProdutoEditar(e.target.value)}
                      value={precoProdutoEditar}
                      type='number'
                      id='preco'
                      class='form-control'
                      defaultValue={produtoModalEditar?.preco_produto}
                      aria-describedby='basic-addon1'
                    />
                  </div>

                  <label htmlFor='preco'>
                    {produtoModalEditar?.payment_type?.[0]?.nomeForma || "CARTÃO DE CRÉDITO"}
                  </label>
                  <div class='input-group mb-3'>
                    <span class='input-group-text' id='basic-addon1'>
                      <i class='fa fa-credit-card' aria-hidden='true' />
                    </span>
                    <input
                      placeholder='CARTÃO DE CRÉDITO'
                      onChange={(e) => setPaymentCredit(e.target.value)}
                      value={paymentCredit}
                      type='number'
                      min={0}
                      id='preco'
                      class='form-control'
                      defaultValue={
                        produtoModalEditar?.payment_type?.[0]?.taxajuros
                      }
                      aria-describedby='basic-addon1'
                    />
                  </div>

                  <label htmlFor='preco'>
                    {produtoModalEditar?.payment_type?.[1]?.nomeForma || "CARTÃO DE DÉBITO"}
                  </label>
                  <div class='input-group mb-3'>
                    <span class='input-group-text' id='basic-addon1'>
                      <i class='fa fa-credit-card' aria-hidden='true' />
                    </span>
                    <input
                      placeholder='CARTÃO DE DÉBITO'
                      onChange={(e) => setPaymentDebit(e.target.value)}
                      value={paymentDebit}
                      type='number'
                      min={0}
                      id='preco'
                      class='form-control'
                      aria-label='Variação do Butano 45 kg'
                      defaultValue={
                        produtoModalEditar?.payment_type?.[1]?.taxajuros
                      }
                      aria-describedby='basic-addon1'
                    />
                  </div>

                  <label htmlFor='preco'>
                    {produtoModalEditar?.payment_type?.[2]?.nomeForma || "ESPÉCIE"}
                  </label>
                  <div class='input-group mb-3'>
                    <span class='input-group-text' id='basic-addon1'>
                      <i class='fa fa-money' aria-hidden='true' />
                    </span>
                    <input
                      placeholder='ESPÉCIE'
                      onChange={(e) => setPaymentCash(e.target.value)}
                      value={paymentCash}
                      type='number'
                      min={0}
                      id='preco'
                      class='form-control'
                      defaultValue={
                        produtoModalEditar?.payment_type?.[2]?.taxajuros
                      }
                      aria-describedby='basic-addon1'
                    />
                  </div>
                </form>
              </div>
              {/* Footer Modal */}
              <div class='modal-footer'>
                <button
                  type='button'
                  class='btn btn-secondary'
                  data-bs-dismiss='modal'
                >
                  Cancelar
                </button>
                <button
                  data-bs-dismiss='modal'
                  type='button'
                  onClick={() => editarProduto()}
                  class='btn btn-warning'
                >
                  Editar <i class='fa fa-check' aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <table class='table'>
          <thead>
            <tr style={{ textAlign: 'center' }}>
              <th scope='col'>Produto</th>
              <th scope='col'>Preço</th>
              <th scope='col'>Crédito</th>
              <th scope='col'>Débito</th>
              <th scope='col'>Espécie</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((value) => {
              return (
                <tr style={{ textAlign: 'center' }}>
                  <td>{value?.nome_produto} </td>
                  <td>R$ {value?.preco_produto},00 </td>
                  {!!value.payment_type ? (
                    <>
                      <td>{value?.payment_type[0]?.taxajuros} </td>
                      <td>{value?.payment_type[1]?.taxajuros} </td>
                      <td>{value?.payment_type[2]?.taxajuros} </td>
                    </>
                  ) : (
                    <></>
                  )}
                  <>
                    <button
                      type='button'
                      data-bs-toggle='modal'
                      data-bs-target='#editarModal'
                      data-bs-whatever='@getbootstrap'
                      class='btn btn-warning'
                      onClick={() => openModal(value)}
                    >
                      Editar
                    </button>
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
