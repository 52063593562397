import firebase from "firebase/app";
import "firebase/auth";

export const authConfig = firebase.initializeApp({
  apiKey: "AIzaSyDKHD4crushj0aUfQp1qJVvlICXfuFYxD4",

  authDomain: "webadministrador-699d5.firebaseapp.com",

  projectId: "webadministrador-699d5",

  storageBucket: "webadministrador-699d5.appspot.com",

  messagingSenderId: "207462624907",

  appId: "1:207462624907:web:b7f9dc46983a646413ae8e",

  measurementId: "G-GVVPFE93VY"
});



