import React, { useState, useEffect, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { authConfig } from "../../auth/config";
import { AuthContext } from "../../auth/AuthContext";

import { Link } from "react-router-dom";

import "./style.css";
import logo from "../../assets/logo.png";

export default function Login({ navigation, history }) {
  const [Iemail, setIEmail] = useState("");
  const [Ipassword, setIPassword] = useState("");

  // const [user, setUser] = useState(undefined);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    console.log(user);
    if (user) {
      console.log(true);
      history.push("/");
    }
  }, [user]);

  function handleSubmit(e) {
    e.preventDefault();
    console.log(Iemail);
    console.log(Ipassword);

    authConfig
      .auth()
      .signInWithEmailAndPassword(Iemail, Ipassword)
      .then((store) => {
        // console.log(`Email: ${store.email} Senha: ${store.uid}`);
        console.log(store);
        history.push("/");
        // setUser(store);
      })
      .catch(function (error) {
        alert("Email e Senha Invalido")
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(`Erro ${errorCode} - ${errorMessage}`);
      });
    // navigation.navigate('Dashboard')
  }

  return (
    <div
      style={{
        background: "#ff9702",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: 10,
          width: 450,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img width={100} src={logo} alt="" />
        <h1>Login</h1>
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={handleSubmit}
        >
          <input
            style={{
              borderWidth: 1,
              borderColor: "gray",
              padding: 10,
              borderRadius: 7,
              marginBottom: 10,
            }}
            placeholder="Email"
            type="email"
            name="email"
            onChange={(event) => setIEmail(event.target.value)}
          />
          <input
            style={{
              borderWidth: 1,
              borderColor: "gray",
              padding: 10,
              borderRadius: 7,
              marginBottom: 10,
            }}
            placeholder="Senha"
            type="password"
            name="password"
            onChange={(event) => setIPassword(event.target.value)}
          />
          <button
            style={{
              borderWidth: 1,
              background: "#ff9702",
              color: "#fff",
              border:"none",
              padding: 10,
              borderRadius: 7,
              marginBottom: 10,
            }}
            type="submit"
          >
            Entrar
          </button>
        </form>
      </div>
      <div>
        <p><Link to="/privacidade">Política de Privacidade</Link></p>
      </div>
    </div>
  );
}
