/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import api from "../../services/api";

import Header from "../../components/header";
import Sidbar from "../../components/sidbar";

export default function index() {
  const [bairros, setBairros] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const [nomeBairro, setNomeBairro] = useState("");
  const [preco13, setPreco13] = useState(0);
  const [preco45, setPreco45] = useState(0);

  const [bairroModalEditar, setbairroModalEditar] = useState();
  const [nomeBairroEditar, setNomeBairroEditar] = useState("");
  const [preco13Editar, setPreco13Editar] = useState(0);
  const [preco45Editar, setPreco45Editar] = useState(0);


  function adiconarNovoBairro() {
    if (nomeBairro.length >= 1 && preco13) {
      api({
        method: "POST",
        url: "/criar_forma",
        data: {
          nomeForma: nomeBairro,
          taxajuros: preco13,
        },
      })
        .then(() => {
          setNomeBairro("");
          setPreco13(0);
        })
        .catch(() => { });
    } else {
      alert("Preencha corretamente os campos...");
    }
  }
  function editarBairro() {
    if(nomeBairroEditar.length >= 1){
      api({
        method: "PUT",
        url: "atualiza_formapagamento",
        headers: { _id: bairroModalEditar._id },
        data: {
          nomeForma: nomeBairroEditar,
          taxajuros: preco13Editar,
        },
      }).then(() => {
        // console.log(res);
        setNomeBairroEditar("");
        setPreco13Editar(0);
        document.location.reload()
        
      })
  }}
  

  useEffect(() => {
    api({
      method: "GET",
      url: "/formapagamento",
    })
      .then((res) => {
        setBairros(res.data);
      })
      .catch(() => { });

    // Buscar Produtos
    api({
      method: "GET",
      url: "/formapagamento",
    })
      .then((res) => {
        console.log(res.data);
        setProdutos(res.data);
      })
      .catch(() => { });
  }, []);
  return (
    <div id="wrapper">
      <Sidbar />
      <Header />
      <div className="content-wrapper">
        {/* <!-- Button trigger modal --> */}
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          data-bs-whatever="@getbootstrap"
        >
          <i className="fa fa-plus"></i>
        </button>

        {/* <!-- 🟢 Modal Criar --> */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Adicionar Forma de Pagamento
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {/* Body Modal */}
              <div class="modal-body">
                <form>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa fa-location-arrow" aria-hidden="true" />
                    </span>
                    <input
                      onChange={(e) => setNomeBairro(e.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="Nome da Forma de Pagamento"
                      aria-label="Nome do Bairro"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa fa-credit-card" aria-hidden="true" />
                    </span>
                    <input
                      onChange={(e) => setPreco13(e.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="Valor da Taxa"
                      aria-label="Variação do Butano 13 kg"
                      aria-describedby="basic-addon1"
                    />
                  </div>

                </form>
              </div>
              {/* Footer Modal */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={() => adiconarNovoBairro()}
                  class="btn btn-success"
                >
                  Adicionar <i class="fa fa-check" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- 🟠 Modal Editar --> */}
        <div
          class="modal fade"
          id="editarModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Editar Forma Pagamento
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setbairroModalEditar()}
                ></button>
              </div>
              {/* Body Modal */}
              <div class="modal-body">
                <form>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa fa-location-arrow" aria-hidden="true" />
                    </span>
                    <input
                      onChange={(e) => setNomeBairroEditar(e.target.value)}
                      type="text"
                      class="form-control"
                      aria-label="Bairro"
                      placeholder={`${bairroModalEditar?.nomeForma}`}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <i class="fa fa-usd" aria-hidden="true" />
                    </span>
                    <input
                      onChange={(e) => setPreco13Editar(e.target.value)}
                      type="number"
                      class="form-control"
                      aria-label="Variação do Butano 45 kg"
                      placeholder={`${bairroModalEditar?.taxajuros}`}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </form>
              </div>
              {/* Footer Modal */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={() => editarBairro()}
                  class="btn btn-warning"
                >
                  Editar <i class="fa fa-check" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th scope="col">#</th>
              <th scope="col">Forma de Pagamento</th>
              <th scope="col">Taxa do Pagamento</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            {bairros.map((value) => {
              return (
                <tr>
                  <th style={{ textTransform: "uppercase" }} scope="row">
                    #{value?._id.slice(-4)}
                  </th>
                  <td> {value?.nomeForma} </td>
                  <td>
                    <span style={{ color: "#3CB371", fontWeight: "bold" }}>
                      R$ {value?.taxajuros}
                    </span>{" "}
                  </td>

                  {/* {produtos.map((produto) => (
                    <td> {produto?.preco_produto} + {value?.variacao13} Total R$ {value?.variacao13 + produto?.preco_produto},00 </td>
                  ))} */}
                  <td>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#editarModal"
                      data-bs-whatever="@getbootstrap"
                      class="btn btn-warning"
                      onClick={() => setbairroModalEditar(value)}
                    >
                    <i className="fa fa-pencil"></i>
                      Editar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
