import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import $ from 'jquery'
import 'datatables.net-bs4'
import "datatables.net-dt"
import 'datatables.net-buttons-bs4'
import "datatables.net-buttons-dt"

// import "./style.css"

export default function TableUsers() {
  const [users, setUsers] = useState([]);
  // const [address, setAddress] = useState([]);
  // const [userModalID, setUserModalID] = useState([]);
  
  useEffect(() => {
    async function loadUser() {
      const response = await api.get("user/");
      const userData = response.data.reverse();
      const users = userData.filter((value) => value.provider == false);
      setUsers(users.slice(0, 5));
    }
    // async function loadAddress() {
    //   const response = await api.get("/dashboard/address/");
    //   setAddress(response.data);
    // }

    // loadAddress();
    loadUser();
  }, [users.length]);

  // let btnOn = document.querySelectorAll(".btn-primary");

  // let i;

  // for (i = 0; i < btnOn.length; i++) {
  //   btnOn[i].addEventListener("click", function() {
  //     let card = this.parentNode; //pega elemento Pai
  //     let idUser = card.childNodes[0].textContent // pegando ID
  //     setUserModalID(idUser)
  //   });
  // }


  return (
    <>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">(Top 5) Novos Usuários<div class="card-action">
          <div class="dropdown">
          <Link to="/dashboard/address">Ver Todos</Link>
          
          </div>
        </div>
        </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Celular</th>
                    <th>Funcionário</th>
                  </tr>
                </thead>
                <tbody>
                {users.map(user => (
                  <tr>
                    <td><span class="badge badge-success shadow-success">Novo</span></td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.telephone}</td>
                    <td>{user.dateBirth}</td>
                  </tr>
                ))}
                </tbody>

                </table>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}
