import React, { useState, useMemo, memo, useRef, useEffect } from 'react';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import $ from 'jquery';


import { Animated } from 'react-animated-css';

// Components
import Sidbar from '../../../components/sidbar/index';
import Header from '../../../components/header/index';

import api from '../../../services/api';

import UIfx from 'uifx';
import sound from '../../../assets/olhaogas.mp3';

const song = new UIfx(sound, { volume: 1.0 });

function Dashboard() {
  const [solicitatations, setSoliciatations] = useState([]);
  const [address, setAddress] = useState([]);
  const [idSolicitation, setIdSolicitation] = useState([]);
  const [deliveryMans, setDeliveryMans] = useState([]);
  const [entregador, setEntregador] = useState([]);
  const [formapagamento, setFormaPagamento] = useState([]);
  const [alerte, setAlerte] = useState(false);
  const [modalCancelId, setModalCancelId] = useState('');
  const [cancelValue, setCancelValue] = useState('');
  const [newSolicitatation, setNewSoliciatation] = useState([]);
  const [lastSolicitation, setLastSolicitation] = useState(null);

  function sendAlert(alert) {
    if (alert) {
      try {
        if (song) {
          song.play();
        }

        const notificationPersonalizationMessage = `Um Cliente Fez um Novo Pedido:{JSON.stringify(newSolicitatation)}`;

        store.addNotification({
          title: '🦄 Chegou Um Novo Pedido ',
          message: notificationPersonalizationMessage,
          type: 'default',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: { duration: 3000, showIcon: true, pauseOnHover: true }
        });
        return store;
      } catch (error) {
        console.log('OCORREU UM ERRO AO TENTAR PRODUZIR O SOM AQUI', error);
      }
    }
  }

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const previousIdRef = useRef(null);

  useMemo(() => {
    async function loadSolicitations() {
      const response = await api.get('listar_pedidos');
      const solicitations = response.data;
      console.log(solicitatations);
      setSoliciatations(solicitations);
      setLoading(false);

      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
        return;
      }

      const latestSolicitation = solicitations[solicitations.length - 1];
      if (latestSolicitation) {
        const latestId = latestSolicitation._id;

        if (
          previousIdRef.current !== null &&
          latestId !== previousIdRef.current
        ) {
          console.log('O id foi alterado:', latestId);
          setNewSoliciatation([latestSolicitation]);
          setLastSolicitation(latestSolicitation._id);
          sendAlert(true);
        } else {
          console.log('O id não foi alterado:', latestId);
        }

        previousIdRef.current = latestId;
      }
    }
    async function loadAddress() {
      const response = await api.get('endereco_user');
      setAddress(response.data);
    }
    async function loadDeliveryMan() {
      const response = await api.get('listar_entregadores');
      setDeliveryMans(response.data);
    }
    async function loadFormaPagamento() {
      const response = await api.get('formapagamento');
      setFormaPagamento(response.data);
    }
    loadAddress();
    loadSolicitations();
    loadDeliveryMan();
    loadFormaPagamento();
  }, [solicitatations]);

  // pega ID de cada Solicitação
  useEffect(
    function getIdSolicitation() {
      const btnMostar = $('.btnMostrar');
      let i = 0;

      for (i; i < btnMostar.length; i++) {
        btnMostar[i].addEventListener('click', function () {
          let box = this.parentNode; //pega elemento Pai
          let idSolicitation = box.childNodes[0].textContent; // pegando ID
          setIdSolicitation(idSolicitation);
        });
      }
    },
    [solicitatations]
  );

  function Loading() {
    return <h1>Aguarde...</h1>;
  }

  // Função: Seta um Deliveryman
  async function handleUpdate(event) {
    event.preventDefault();
    if (
      !entregador.length ||
      entregador === 'Selecione o Entrega' ||
      entregador === 'none'
    ) {
      alert('Selecione o Entregador');
    } else {
      let pedido_id = idSolicitation;
      const response = await api.put(
        'enviar_entregador',
        { entregador: entregador },
        { headers: { pedido_id } }
      );
      // Animaçãp: Botão Amarelo Pula
      document.querySelector('.nova-editada').classList.add('animated');
      document.querySelector('.nova-editada').classList.add('bounce');
      setTimeout(() => {
        document.querySelector('.nova-editada').classList.remove('animated');
        document.querySelector('.nova-editada').classList.remove('bounce');
      }, 1000);
      // Animação: Formulrio sai
      let form = document.querySelector('.form');
      form.classList.add('animated');
      form.classList.add('zoomOut');
      setTimeout(() => {
        setIdSolicitation([]);
      }, 1000);
    }
  }

  function cancelarPedido(_id) {
    if (!cancelValue) {
      alert('Selecione um motivo para Cancelar este pedido');
    } else {
      console.log(1);
      api({
        url: 'cancelPedido',
        method: 'PUT',
        headers: { pedido_id: _id },
        data: { message: cancelValue }
      }).then((res) => {
        console.log(res);
      });

      // Animação: Formulrio sai
      let form = document.querySelector('.form');
      form.classList.add('animated');
      form.classList.add('zoomOut');
      setTimeout(() => {
        setIdSolicitation([]);
      }, 1000);
    }
  }

  function formatedDate(date) {
    var dtPartida = date;
    var ano = dtPartida.slice(0, 4);
    var mes = dtPartida.slice(4, 6);
    var dia = dtPartida.slice(6, 8);
    var hr = dtPartida.slice(9, 11);
    var mn = dtPartida.slice(12, 14);

    var convertedDate = `${dia}/${mes}/${ano} às ${hr}:${mn}`;
    return convertedDate;
  }

  const productIsObj = (pedido) => typeof pedido?.produto === 'object';

  return (
    <div id='wrapper'>
      <Sidbar />
      <Header />
      <div className='content-wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            {/* Navs */}

            {/* Action Header */}
            <div className='solicitations1  col-sm col-lg-4'>
              <div className='card-body card-body1'>
                <ul
                  className='nav nav-pills nav-pills-primary top-icon'
                  style={{ borderBottom: 'none' }}
                >
                  <li className='nav-item nova-solicitacao'>
                    <a
                      className='nav-link nav-link1 active'
                      data-toggle='tab'
                      href='#tabe-7'
                    >
                      <span className='badge badge1 badge-primary shadow-primary m-1'>
                        {
                          solicitatations.filter(
                            (solic) =>
                              !solic?.entregador &&
                              solic?.cancel == null &&
                              solic?.status !== 'entregar'
                          ).length
                        }
                      </span>
                      <i className='icon-layers'></i>
                      <span className='hidden-xs'>Novas</span>
                    </a>
                  </li>
                  <li className='nav-item nova-editada'>
                    <a
                      className='nav-link nav-link1'
                      data-toggle='tab'
                      href='#tabe-8'
                    >
                      <span className='badge badge1 badge-warning shadow-warning m-1'>
                        {
                          solicitatations.filter(
                            (solic) =>
                              solic?.entregador &&
                              solic?.status !== 'entregar' &&
                              solic?.cancel == null
                          ).length
                        }
                      </span>
                      <i className='icon-cursor'></i>
                      <span className='hidden-xs'>Enviadas</span>
                    </a>
                  </li>
                  <li className='nav-item nova-concluida'>
                    <a
                      className='nav-link nav-link1'
                      data-toggle='tab'
                      href='#tabe-9'
                    >
                      <span className='badge badge1 badge-success shadow-success  m-1'>
                        {
                          solicitatations.filter(
                            (solic) => solic?.status === 'entregar'
                          ).length
                        }
                      </span>
                      <i className='icon-check'></i>
                      <span className='hidden-xs'>Concluídas</span>
                    </a>
                  </li>
                  <li className='nav-item nova-concluida'>
                    <a
                      className='nav-link nav-link1'
                      data-toggle='tab'
                      href='#tabe-10'
                    >
                      <span className='badge badge1 badge-danger shadow-danger  m-1'>
                        {
                          solicitatations.filter((solic) => solic?.cancel)
                            .length
                        }
                      </span>
                      <i className='icon-trash'></i>
                      <span className='hidden-xs'>Cancelados</span>
                    </a>
                  </li>
                </ul>

                {/* Lista de Solicitações  */}
                <div className='tab-content solicitations'>
                  {/* 
                //  Lista de Novos Pedidos 
                */}
                  <div
                    id='tabe-7'
                    className='container container1 tab-pane active'
                  >
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        <ul className='listSo'>
                          {solicitatations
                            .filter(
                              (solic) =>
                                !solic.entregador &&
                                solic?.status !== 'entregar' &&
                                solic.cancel == null
                            )
                            .map((solic) => (
                              <Animated
                                animationIn='bounceIn'
                                animationOut='fadeOut'
                                isVisible={true}
                              >
                                <li key={solic?._id} id='solicitation'>
                                  <div
                                    className='solicitationInfo bg-new'
                                    key={solic?._id}
                                  >
                                    <span
                                      className='id'
                                      style={{ display: 'none' }}
                                    >
                                      {solic?._id}
                                    </span>
                                    <div className='lini-info'>
                                      <h5>{solic?.user?.name}</h5>
                                    </div>
                                    <span className='type'>
                                      <strong>Tipo: </strong>{' '}
                                      {productIsObj(solic)
                                        ? solic?.produto?.nome_produto
                                        : solic?.produto}
                                    </span>
                                    <span className='pay'>
                                      <strong>Pagamento: </strong>
                                      {productIsObj(solic)
                                        ? solic.produto.payment_type?.[0]
                                            ?.nomeForma
                                        : solic?.pagamento?.nomeForma}
                                    </span>
                                    <button className='btnMostrar btn waves-effect waves-light'>
                                      Mostrar
                                    </button>
                                  </div>
                                </li>
                              </Animated>
                            ))
                            .reverse()}
                        </ul>
                      </>
                    )}
                  </div>

                  {/* 
                //  Lista de Pedidos Enviados
                */}
                  <div
                    id='tabe-8'
                    className='container container1 tab-pane fade'
                  >
                    <ul className='listSo'>
                      {solicitatations
                        .filter(
                          (solic) =>
                            solic.entregador &&
                            solic.status !== 'entregar' &&
                            solic.cancel == null
                        )
                        .map((solic) => (
                          <Animated
                            animationIn='bounceIn'
                            animationOut='fadeOut'
                            isVisible={true}
                          >
                            <li key={solic._id} id='solicitation'>
                              <div
                                className='solicitationInfo bg-enviado'
                                key={solic._id}
                              >
                                <span
                                  className='id'
                                  style={{ display: 'none' }}
                                >
                                  {solic._id}
                                </span>
                                <div className='lini-info'>
                                  <h5>{solic?.user?.name}</h5>
                                </div>
                                <span className='type'>
                                  <strong>Tipo: </strong> {solic.gas}
                                </span>
                                <span className='pay'>
                                  <strong>Pagamento: </strong>
                                  {solic.pay}
                                </span>
                                {solic.status === 'iniciar' ? (
                                  <span
                                    className='pin'
                                    style={{
                                      background: '#fff6c4',
                                      color: '#FFA500'
                                    }}
                                  >
                                    <i
                                      style={{ color: '#FFA500' }}
                                      className='fas fa-flag'
                                    />{' '}
                                    Iniciado
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <button className='btnMostrar btn waves-effect waves-light'>
                                  Mostrar
                                </button>
                              </div>
                            </li>
                          </Animated>
                        ))
                        .reverse()}
                    </ul>
                  </div>
                  {/* 
                //  Lista de Novos Concluídos 
                */}
                  <div
                    id='tabe-9'
                    className='container container1 tab-pane fade'
                  >
                    <ul className='listSo'>
                      {solicitatations
                        .filter((solic) => solic?.status === 'entregar')
                        .map((solic) => (
                          <Animated
                            animationIn='bounceIn'
                            animationOut='fadeOut'
                            isVisible={true}
                          >
                            <li key={solic?._id} id='solicitation'>
                              <div
                                className='solicitationInfo bg-ok'
                                key={solic?._id}
                              >
                                <span
                                  className='id'
                                  style={{ display: 'none' }}
                                >
                                  {solic?._id}
                                </span>
                                <div className='lini-info'>
                                  <h5>{solic?.user?.name}</h5>
                                </div>
                                <span className='type'>
                                  <strong>Tipo: </strong> {solic?.gas}
                                </span>
                                <span className='pay'>
                                  <strong>Pagamento: </strong>
                                  {solic?.pay}
                                </span>
                                <button className='btnMostrar btn waves-effect waves-light'>
                                  Mostrar
                                </button>
                                {solic?.status === 'entregar' ? (
                                  <span
                                    className='pin'
                                    style={{
                                      background: '#b8ffc9',
                                      color: '#28a745'
                                    }}
                                  >
                                    <i
                                      style={{ color: '#28a745' }}
                                      className='fas fa-check'
                                    />{' '}
                                    Entregue
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </li>
                          </Animated>
                        ))
                        .reverse()}
                    </ul>
                  </div>
                  {/* 
                //  Lista Pedidos Cancelador
                */}
                  <div
                    id='tabe-10'
                    className='container container1 tab-pane fade'
                  >
                    <ul className='listSo'>
                      {solicitatations
                        .filter((solic) => solic?.cancel)
                        .map((solic) => (
                          <Animated
                            animationIn='bounceIn'
                            animationOut='fadeOut'
                            isVisible={true}
                          >
                            <li key={solic?._id} id='solicitation'>
                              <div
                                className='solicitationInfo bg-cancel'
                                key={solic?._id}
                              >
                                <span
                                  className='id'
                                  style={{ display: 'none' }}
                                >
                                  {solic?._id}
                                </span>
                                <div className='lini-info'>
                                  <h5>{solic?.user?.name}</h5>
                                </div>
                                <span className='type'>
                                  <strong>Tipo: </strong> {solic?.gas}
                                </span>
                                <span className='pay'>
                                  <strong>Pagamento: </strong>
                                  {solic?.pay}
                                </span>
                                <button className='btnMostrar btn waves-effect waves-light'>
                                  Pedido Cancelado
                                </button>
                              </div>
                            </li>
                          </Animated>
                        ))
                        .reverse()}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Formulário */}
            <div className='infoSolicitations2 col-sm col-lg-8'>
              <div className='datesSolicitations form-new'>
                {solicitatations
                  .filter(
                    (solicitation) => solicitation?._id === idSolicitation
                  )
                  .map((solicitation) => (
                    <Animated animationIn='zoomInUp' isVisible={true}>
                      <form className='form'>
                        {/* Usuario */}
                        <div className='form-row'>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputEmail4'>Usuário</label>
                            <input
                              type='email'
                              className='form-control'
                              value={solicitation?.user?.name}
                              disabled
                              id='text'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputEmail4'>Email</label>
                            <input
                              type='email'
                              className='form-control'
                              value={solicitation?.user?.email}
                              disabled
                              id='text'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label htmlFor='text'>Telefone</label>
                            <input
                              type='text'
                              className='form-control'
                              value={solicitation?.user?.telephone}
                              disabled
                              id='text'
                            />
                          </div>
                        </div>
                        {/* Endereços */}
                        <div className='form-row'>
                          <div className='form-group col-md-12'>
                            <label htmlFor='inputEmail4'>Endereço</label>
                            <input
                              type='email'
                              className='form-control'
                              value={`${solicitation?.endereco?.rua}, Nº ${solicitation?.endereco?.numero}, Complemento: ${solicitation?.endereco?.complemento}, Bairro: ${solicitation?.endereco?.bairro?.bairro} -  ${solicitation?.endereco?.cep}`}
                              disabled
                            />
                          </div>
                        </div>
                        {/* Pagamento */}
                        <div className='form-row'>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputAddress'>
                              Forma de Pagamento
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              value={
                                productIsObj(solicitation)
                                  ? solicitation.produto.payment_type?.[0]
                                      ?.nomeForma
                                  : solicitation?.pagamento?.nomeForma
                              }
                              disabled
                              id='text'
                            />
                          </div>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputAddress'>Tipo</label>
                            <input
                              type='text'
                              className='form-control'
                              value={
                                productIsObj(solicitation)
                                  ? solicitation?.produto?.nome_produto
                                  : solicitation?.produto
                              }
                              disabled
                              id='text'
                            />
                          </div>
                          <div className='form-group col-md-2'>
                            <label htmlFor='inputAddress'>Quantidade</label>
                            <input
                              type='text'
                              className='form-control'
                              value={solicitation?.quantidade}
                              disabled
                              id='inputAddress'
                              placeholder='1234 Main St'
                            />
                          </div>

                          <div className='form-group col-md-4'>
                            <label htmlFor='inputAddress'>Total</label>
                            <input
                              type='text'
                              className='form-control'
                              value={`R$ ${solicitation?.total},00`}
                              disabled
                              id='text'
                            />
                          </div>

                          <div className='form-group col-md-4'>
                            <label htmlFor='inputAddress'>
                              Troco para quanto
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              value={`R$ ${solicitation?.troco}`}
                              disabled
                              id='text'
                            />
                          </div>
                          {/* Status */}
                          {solicitation?.status === 'iniciar' ? (
                            <div className='form-group col-md-2'>
                              <label htmlFor='inputAddress'>Status</label>
                              <input
                                type='text'
                                className='form-control'
                                style={{
                                  background: '#fff6c4',
                                  color: '#FFA500'
                                }}
                                value='INICIADO'
                                disabled
                              />
                            </div>
                          ) : (
                            <>
                              {solicitation?.status === 'entregar' ? (
                                <div className='form-group col-md-2'>
                                  <label htmlFor='inputAddress'>Status</label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    style={{
                                      background: '#b8ffc9',
                                      color: '#28a745'
                                    }}
                                    value='ENTREGUE'
                                    disabled
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                        {/* Tempo/Atualizaçoes */}
                        <div className='form-row'>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputEmail4'>Data de Criação</label>
                            {solicitation?.createdAt ? (
                              <input
                                type='email'
                                className='form-control'
                                value={formatedDate(solicitation?.createdAt)}
                                disabled
                                id='text'
                              />
                            ) : (
                              <input
                                type='email'
                                className='form-control'
                                value={''}
                                disabled
                                id='text'
                              />
                            )}
                          </div>
                          <div className='form-group col-md-4'>
                            <label htmlFor='inputEmail4'>
                              Última Atualização
                            </label>
                            {solicitation?.updatedAtt ? (
                              <input
                                type='email'
                                className='form-control'
                                value={formatedDate(solicitation?.updatedAtt)}
                                disabled
                                id='text'
                              />
                            ) : (
                              <input
                                type='email'
                                className='form-control'
                                value={''}
                                disabled
                                id='text'
                              />
                            )}
                          </div>
                          <div className='form-group col-md-4'>
                            <label htmlFor='text'>Tempo de Entrega</label>
                            {solicitation?.status === 'entregar' ? (
                              <input
                                type='email'
                                className='form-control'
                                value={solicitation?.tempo}
                                disabled
                                id='text'
                              />
                            ) : (
                              <input
                                type='email'
                                className='form-control'
                                value={''}
                                disabled
                                id='text'
                              />
                            )}
                          </div>
                        </div>

                        {/* Entregador */}
                        <div className='form-group'>
                          <label htmlFor='inputState'>
                            Selecione o Entregador
                          </label>
                          {!solicitation?.entregador ? (
                            <select
                              defaultValue={'none'}
                              value={entregador}
                              onChange={(e) => setEntregador(e.target.value)}
                              id='inputState'
                              className='selected form-control'
                            >
                              <option value='none' selected>
                                Selecione o Entregador
                              </option>
                              {deliveryMans.map((item, index) => (
                                <option key={item?._id} value={item?._id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <>
                              {solicitation?.entregador &&
                              solicitation?.status !== 'entregar' &&
                              solicitation?.cancel == null ? (
                                <select
                                  defaultValue={'none'}
                                  value={entregador}
                                  onChange={(e) =>
                                    setEntregador(e.target.value)
                                  }
                                  id='inputState'
                                  className='form-control'
                                >
                                  <option
                                    selected
                                    style={{ background: '#D3D3D3' }}
                                  >
                                    {solicitation?.entregador?.name}
                                  </option>
                                  {deliveryMans
                                    .filter(
                                      (i) =>
                                        i.name !==
                                        solicitation?.entregador?.name
                                    )
                                    .map((item, index) => (
                                      <option key={item?._id} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))}
                                </select>
                              ) : (
                                <>
                                  {solicitation?.status === 'entregar' ? (
                                    <select
                                      defaultValue={'none'}
                                      value={entregador}
                                      onChange={(e) =>
                                        setEntregador(e.target.value)
                                      }
                                      id='inputState'
                                      className='form-control'
                                    >
                                      <option disable selected>
                                        {/* entregador  */}
                                        {solicitation?.entregador?.name}
                                      </option>
                                    </select>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>

                        {/* Buttões */}
                        {solicitation?.cancel == null ? (
                          <>
                            {solicitation?.status === 'entregar' ? (
                              <button
                                disabled
                                type='submit'
                                className='btn btn-success'
                              >
                                Concluido
                              </button>
                            ) : (
                              <>
                                {!solicitation?.entregador ? (
                                  <>
                                    <button
                                      onClick={handleUpdate}
                                      type='submit'
                                      className='btn btn-primary'
                                    >
                                      Enviar
                                    </button>
                                    <button
                                      onClick={() =>
                                        setModalCancelId(solicitation?._id)
                                      }
                                      style={{ marginLeft: 10 }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-bs-toggle='modal'
                                      data-bs-target='#exampleModal'
                                      data-bs-whatever='@getbootstrap'
                                    >
                                      Cancelar
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={handleUpdate}
                                      style={{ color: '#fff' }}
                                      type='submit'
                                      className='btn bg-warning'
                                    >
                                      Editar
                                    </button>

                                    <button
                                      onClick={() =>
                                        setModalCancelId(solicitation?._id)
                                      }
                                      style={{ marginLeft: 10 }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-bs-toggle='modal'
                                      data-bs-target='#exampleModal'
                                      data-bs-whatever='@getbootstrap'
                                    >
                                      Cancelar
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <button
                            disabled
                            type='submit'
                            className='btn btn-danger'
                          >
                            Cancelado: {solicitation?.cancel?.message}
                          </button>
                        )}
                      </form>
                    </Animated>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* fim main */}
      </div>
      {/* <!-- Modal --> */}
      <div
        class='modal fade'
        id='exampleModal'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Cancelar Pedido
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            {/* Body Modal */}
            <div class='modal-body'>
              <h5>Motivo do Cancelamento</h5>
              <form>
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios1'
                    value='Área fechada'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                    // checked
                  />
                  <label class='form-check-label' for='exampleRadios1'>
                    Área fechada
                  </label>
                </div>
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios2'
                    value='Fora de rota'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios2'>
                    Fora de rota
                  </label>
                </div>
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios3'
                    value='Problemas no pagamento'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios3'>
                    Problemas no pagamento
                  </label>
                </div>
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios4'
                    value='Comprou com outro'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios4'>
                    Comprou com outro
                  </label>
                </div>
                {/* novas opcoes de motivo de cancelamento*/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios5'
                    value='SEM MOTIVO ESPECIFICADO'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios5'>
                    SEM MOTIVO ESPECIFICADO
                  </label>
                </div>
                {/* */}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios6'
                    value='CLIENTE NÃO ENCONTRADO NO LOCAL'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios6'>
                    CLIENTE NÃO ENCONTRADO NO LOCAL
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios7'
                    value='CLIENTE DESISTIU DA COMPRA'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios7'>
                    CLIENTE DESISTIU DA COMPRA
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios8'
                    value='DESCULPE, PREÇO ANTIGO'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios8'>
                    DESCULPE, PREÇO ANTIGO
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios9'
                    value='DUPLICIDADE'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios9'>
                    DUPLICIDADE
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios10'
                    value='ENDEREÇO NÃO ENCONTRADO'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios10'>
                    ENDEREÇO NÃO ENCONTRADO
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios11'
                    value='ALTA DEMANDA/ MUITOS PEDIDOS A FRENTE'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios11'>
                    ALTA DEMANDA/ MUITOS PEDIDOS A FRENTE
                  </label>
                </div>
                {/**/}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios12'
                    value='REVENDA FECHADA'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios12'>
                    REVENDA FECHADA
                  </label>
                </div>
                {/* */}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios13'
                    value='SEM ENTREGADOR NO MOMENTO'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios13'>
                    SEM ENTREGADOR NO MOMENTO
                  </label>
                </div>
                {/* */}
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='radio'
                    name='exampleRadios'
                    id='exampleRadios14'
                    value='SEM ESTOQUE DE GÁS NO MOMENTO'
                    onInputCapture={(value) =>
                      setCancelValue(value?.target?.value)
                    }
                  />
                  <label class='form-check-label' for='exampleRadios14'>
                    SEM ESTOQUE DE GÁS NO MOMENTO
                  </label>
                </div>
                {/* fim das novas opcoes de motivo de cancelamento*/}
              </form>
            </div>
            {/* Footer Modal */}
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Fechar
              </button>
              <button
                data-bs-dismiss='modal'
                type='button'
                onClick={() => cancelarPedido(modalCancelId)}
                class='btn btn-danger'
              >
                Efetuar Cancelamento{' '}
                <i class='fa fa-trash' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(Dashboard);
