import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export function PrivateRoutes(props) {
  const { component: RouteCompenent, ...other } = props;
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...other}
      render={(routeProps) =>
        !!user ? (
          <RouteCompenent {...routeProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  );
}
