/** @format */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-dt";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons-dt";
// queries
import { useQuery } from "react-query";

import "./style.css";
import avatar from "../../assets/profiles/avatar.jpg";


export default function TableAddress() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [address, setAddress] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(10);

  const [solicitations, setSolicitations] = useState([]);
  const [userModalID, setUserModalID] = useState([]);

  function Loading() {
    return <h1>Aguarde...</h1>;
  }

  const [loading, setLoading] = useState(true);

  async function loadUser() {
    setLoading(true)

    try {
      const response = await api.get("user/");
      const userData = response.data.reverse();
      const users = userData.filter((value) => value.provider === false);
      setUsers(users);
      setLoading(false);
      const totalPages = Math.ceil(users.length / selectedQuantity);
      const arrayPages = [];
      for (let index = 1; index <= totalPages; index++) {
        arrayPages.push(index);
      }

      setPages(arrayPages);
    } catch (error) {
      setLoading(false);
    }

  }

  useEffect(() => {
    async function loadAddress() {
      const response = await api.get("enderecos/");
      console.log(response.data);
      setAddress(response.data);
    }

    loadAddress();
    loadUser();
  }, []);


  let btnOn = document.querySelectorAll(".btn-primary");

  let i;

  for (i = 0; i < btnOn.length; i++) {
    btnOn[i].addEventListener("click", function () {
      let card = this.parentNode; //pega elemento Pai

      //let idUser = card.childNodes[0].textContent; // pegando ID
      let idUser = card.childNodes[0].innerHTML
      setUserModalID(idUser);
    });
  }

  function formatedDate(date) {
    var dtPartida = date;
    var ano = dtPartida.slice(0, 4);
    var mes = dtPartida.slice(4, 6);
    var dia = dtPartida.slice(6, 8);
    var hr = dtPartida.slice(9, 11);
    var mn = dtPartida.slice(12, 14);

    var convertedDate = `${dia}/${mes}/${ano} às ${hr}hrs ${mn}mn`;
    return convertedDate;
  }

  const handleSearch = async () => {

    if (searchTerm === "") {
      await loadUser();
    } else {

      const res = await api.get("user");
      const userData = res.data.reverse();
      const filteredUsers = userData.filter((value)=> {

        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        //PESQUISANDO PELO NOME DE USER
        const lowerCaseName = value.name.toLowerCase();
        const lowerCaseEmail = value.email.toLowerCase();
        const lowerCasePhone = value.telephone.toLowerCase();

        return (
          value.provider === false && (
            lowerCaseName.includes(lowerCaseSearchTerm) ||
            lowerCaseEmail.includes(lowerCaseSearchTerm) ||
            lowerCasePhone.includes(lowerCaseSearchTerm))
        );
      }); 
       setUsers(filteredUsers);
       setSearchTerm('');
    }

    // if (searchTerm === '') {
    //   await loadUser();
    // } else {
    //   const response = await api.get("user/");
    //   const userData = response.data.reverse();
    //   const filteredUsers = userData.filter((value) => value.provider === false && value.name.toLowerCase().includes(searchTerm.toLowerCase()));
    //   setUsers(filteredUsers);
    //   setSearchTerm('');
    // }
  }

  const handleSearchButtonClick = async () => {
    await handleSearch();
  }

  useEffect(() => {
    // loadUser();
  }, [searchTerm, selectedQuantity]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const startIndex = (currentPage - 1) * selectedQuantity;
  const endIndex = startIndex + selectedQuantity;

  const displayedUsers = users.slice(startIndex, endIndex);
  const handleButtonClick = (idUser) => {
    setUserModalID(idUser);
  };


  return (
    <div class="table-responsive">
            <label htmlFor="InputGroupSelect04">Quantidade de Registros p/ página</label>
      <div class="d-flex justify-content-start">
        <div class="input-group">
          <select
            class="custom-select"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            value={selectedQuantity}
            onChange={(event) => setSelectedQuantity(Number(event.target.value))}

          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      <div className="flex justify-content">
        <div className="form-group">
          <label htmlFor="usr">Pesquisar</label>
          <input
            type="text"
            className="form-control"
            id="usr"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <br />
          <button className="btn btn-block btn-success" onClick={handleSearchButtonClick}>
            Pesquisar
          </button>
        </div>
      </div>


      <table
        id="table"
        className="table table-striped table-borderless table-hover"
      >
        <thead className="thead-light">
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Celular</th>
            <th>Endereços (Qtd)</th>
            <th>Açõessss</th>
          </tr>
        </thead>
        <tbody>

          {loading ? (
          <Loading /> 
          ) :
            displayedUsers.map((user) => (
              <tr className="cardUserAddress" key={user?._id}>
                <td className="username">{user?.name}</td>
                <td className="cpf">{user?.email}</td>
                <td className="cell">{user?.telephone}</td>
                <td className="qntdAddress">
                  {
                    address.filter((a) => a?.user === user?._id)
                      .length
                  } endereços
                </td>
                <td>
                  <p style={{ display: "none" }}>{displayedUsers?._id}</p>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light m-1 shadow-primary waves-effect waves-light m-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                  >
                    <i className="icon-eye"
                      onClick={() => handleButtonClick(user._id)}
                    ></i>
                  </button>
                </td>
              </tr>
            ))}


        </tbody>
      </table>

      <nav aria-label="Page navigation example">
        <p>Mostrando  {currentPage} de até {selectedQuantity} itens de {users?.length} registros</p>
        <ul class="pagination justify-content-end">
          {currentPage > 1 && (
            <li class="page-item">
              <a
                class="page-link"
                href="#"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Anterior
              </a>
            </li>
          )}

          {pages
            .slice(currentPage - 1, currentPage + 2)
            .map((page) => (
              <li
                class="page-item"
                key={page}
                onClick={() => handlePageChange(page)}
                style={{ fontWeight: currentPage === page ? 'bold' : 'normal' }}
              >
                <a class="page-link" href="#">
                  {page}
                </a>
              </li>
            ))}

          {currentPage < pages.length && (
            <li class="page-item">
              <a
                class="page-link"
                href="#"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Próxima
              </a>
            </li>
          )}
        </ul>


      </nav>

      {/* Modal de Endereços */}
      {displayedUsers
        .filter((a) => a?._id === userModalID)
        .map((user) => (
          <div
            key={user?._id}
            className="modal fade bd-example-modal-lg"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="row">
                  {/* header */}
                  {/* <div className="modal-header">
              <h5 className="modal-title font-weight-bold">Endereços de {a.user.name}</h5>
              <button type="button" className="close" data-dismiss="modal"aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
             */}

                  {/* Profiles */}

                  <div className="col-sm col-lg-4" style={{ paddingRight: 0 }}>
                    <div class="profile-card-2">
                      <div
                        class="card profile-primary"
                        style={{ boxShadow: "none" }}
                      >
                        <div class="card-body text-center">
                          <div class="user-box">
                            <img src={avatar} alt="user avatar" />
                          </div>
                          <h5 class="mb-1">{user?.name}</h5>
                          <h6 class="text-muted">{user?.email}</h6>
                          <h6 class="text-muted">Senha: {user?.password}</h6>
                          <div class="row">
                            <div class="col border-light border-right p-2">
                              <h4 class="mb-0 text-success">
                                {
                                  address.filter((a) => a?.user === user?._id)
                                    .length
                                }
                              </h4>
                              <p class="mb-0">Endereços</p>
                            </div>
                            <div class="col p-2">
                              <h4 class="mb-0 text-warning">
                                {
                                  solicitations.filter(
                                    (a) => a?.user?._id === user?._id
                                  ).length
                                }
                              </h4>
                              <p class="mb-0">Pedidos</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Modal */}

                  <div className="col-sm col-lg-8" style={{ paddingLeft: 0 }}>
                    <div id="accordion4">
                      {/* Endereços */}
                      <div class="card mb-2 border border-success">
                        <div class="card-header bg-success">
                          <button
                            class="btn btn-link shadow-none text-white"
                            data-toggle="collapse"
                            data-target="#collapse-10"
                            aria-expanded="true"
                            aria-controls="collapse-10"
                          >
                            Lista Endereços
                          </button>
                        </div>
                        <div
                          id="collapse-10"
                          class="collapse show"
                          data-parent="#accordion4"
                        >
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Rua</th>
                                    <th>CEP</th>
                                    <th>Bairro</th>
                                    <th>Ponto de Referência</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {address
                                    .filter((a) => a?.user === user?._id)
                                    .map((a) => (
                                      <tr>
                                        <td>
                                          <span class="badge badge-success shadow-success">
                                            Novo
                                          </span>
                                        </td>
                                        <td>{a?.rua}</td>
                                        <td>{a?.cep}</td>
                                        <td>{a?.bairro?.bairro}</td>
                                        <td>{a?.numero}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Colaps 2 Pedidos */}
                      <div class="card mb-2 border border-info">
                        <div class="card-header bg-info">
                          <button
                            class="btn btn-link shadow-none text-white collapsed"
                            data-toggle="collapse"
                            data-target="#collapse-11"
                            aria-expanded="false"
                            aria-controls="collapse-11"
                          >
                            Lista Pedidos
                          </button>
                        </div>
                        <div
                          id="collapse-11"
                          style={{ padding: 5 }}
                          class="collapse"
                          data-parent="#accordion4"
                        >
                          <div class="card-body">
                            {solicitations
                              ?.filter((a) => a?.user?._id === displayedUsers?._id)
                              ?.map((soli) => (
                                <div
                                  className="card "
                                  style={{
                                    marginBottom: 10,
                                    padding: 5,
                                    borderColor: "rgba(253, 53, 80,.2)",
                                    borderStyle: "dashed",
                                    borderWidth: 2,
                                  }}
                                >
                                  <div className="row">
                                    <div className="col text-info">
                                      <strong>Endereço: </strong>
                                      {`Rua: ${soli?.endereco?.rua}, Nº${soli?.endereco?.numero}, Bairro: ${soli?.endereco?.bairro?.bairro} -  ${soli?.endereco?.cep}`}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col text-info">
                                      <strong>Cliente: </strong>
                                      {soli?.user?.name}
                                    </div>
                                    <div className="col text-info">
                                      <strong>E-mail: </strong>
                                      {soli?.user?.email}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col text-info">
                                      <strong>Celular: </strong>
                                      {soli?.user?.telephone}
                                    </div>
                                  </div>
                                  {console.log('TESTE ', soli?.pagamento)}
                                  {/* 
                                    <div className="col text-info">
                                      <strong>Pagamento: </strong>
                                      {soli?.pagamento}
                                    </div>
                                  */}


                                  <div className="row">
                                    <div className="col text-info">
                                      <strong>Produto: </strong>
                                      {soli?.produto}
                                    </div>
                                    <div className="col text-info">
                                      <strong>QT: </strong>
                                      {soli?.quantidade}
                                    </div>
                                  </div>

                                  {soli?.deliveryman && soli?.updatedAtt ? (
                                    <>
                                      <div className="row">
                                        <div className="col text-info">
                                          <strong>Entregador: </strong>
                                          {soli?.deliveryman?.name}
                                        </div>
                                        <div className="col text-info">
                                          <strong>Celular: </strong>
                                          {soli?.deliveryman?.cell}
                                        </div>
                                      </div>
                                      <div className="row">
                                        {/* <div className="col text-info">
                                          <strong>Pedido em: </strong>
                                          {formatedDate(soli.createdAtt)}
                                        </div> */}
                                        <div className="col text-info">
                                          <strong>Atualizado: </strong>
                                          {formatedDate(soli?.updatedAtt)}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col text-info">
                                          <strong>Tempo: </strong>
                                          {soli?.time}
                                        </div>
                                        <div className="col text-info">
                                          <strong>Status: </strong>
                                          {soli?.status}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}


                                </div>
                              ))}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}