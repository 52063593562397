import React, { useState, useEffect } from "react";

// Components
import Header from "../../components/header/index";
import Sidbar from "../../components/sidbar/index";
import Card from "../../components/cardStatistics/index";
import TableUser from "../../components/tables/users";
// Outhers
import api from "../../services/api";
// import "./index.css";

export default function Dashboard() {
  return (
    <div id="wrapper">
      <Sidbar />
      <Header />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row mt-4">
            <Card />
          </div>
          <div className="row">
            <TableUser />
          </div>
        </div>
      </div>
    </div>
  );
}
