import React, { useState, useEffect } from 'react';
// Components
import Sidbar from '../../../components/sidbar/index';
import Header from '../../../components/header/index';

import TableAddress from '../../../components/cardUserAddress/address';
import '../index.css';

export default function Dashboard() {
  return (
    <div className="wrapper">
      <Sidbar />
      <Header />
      <div className="content-wrapper">
        <div className="container-fluid">
          {/* Main */}
          <div className="container-table-user">
            <div className="title-table">
              <h3 className="font-weight-bold">Tabela de Usários</h3>
            </div>
            <div className="contentCards">
              <TableAddress />
            </div>
          </div>
          {/* fim main */}
        </div>
      </div>
    </div>
  );
}
