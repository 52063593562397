import React from 'react';
import Routes from './routes';
import ReactNotifications from 'react-notifications-component';

import './config/ReactotronConfig';
// import './App.css';
import './styles/app-style.css';
import './styles/simplebar.css';
import './styles/bootstrap.min.css';
import './styles/bootstrap.min.css';
import './styles/icons.css';
import './styles/sidebar-menu.css';

import { QueryClient, QueryClientProvider } from 'react-query';

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ReactNotifications />
      </QueryClientProvider>
    </>
  );
}

export default App;
