import React, { useState, useEffect } from "react";
import api from "../../../services/api";

import Sidbar from "../../../components/sidbar/index";
import Header from "../../../components/header/index";

export default function Register() {
  const [entregador, setEntregador] = useState([]);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");

  useEffect(() => {
    console.log("ola");
    setReload(true);
    async function loadEntregador() {
      const response = await api.get("listar_entregadores");
      console.log(response.data);
      setEntregador(response.data);
    }
    loadEntregador();
  }, [reload]);

  function deleteEntregador(id) {
    setReload(true);
    api({
      method: "DELETE",
      headers: { _id: id },
      url: "deleteuser"
    }).then(() => {
      alert("Entregador Deletado")
      setReload(false);
    })
  }

  async function handleSubmit(even) {
    even.preventDefault();
    const response = await api.post("adduser", {
      name,
      telephone,
      email,
      password,
      provider: true,
    });
    setName("");
    setTelephone("");
    setEmail("");
    setpassword("");
    console.log(response.data);
    setReload(false);
  }
  return (
    <div className="wrapper">
      <Header />
      <Sidbar />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm col-lg-4">
              <div className="div-cardDeliveryman">
                <h4>Cadastro de Entregadores</h4>
                <form onSubmit={handleSubmit} className="form-deliveryman ">
                  <input
                    className="form-group"
                    type="text"
                    id="name"
                    placeholder="Nome do Entregador"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    className="form-group"
                    type="number"
                    id="telefone"
                    placeholder="Telefone"
                    value={telephone}
                    onChange={(event) => setTelephone(event.target.value)}
                  />
                  <input
                    className="form-group"
                    type="email"
                    id="telefone"
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <input
                    className="form-group"
                    type="password"
                    id="telefone"
                    placeholder="Senha do App"
                    value={password}
                    onChange={(event) => setpassword(event.target.value)}
                  />
                  <br />
                  <button className="btn btn-primary" type="submit">
                    Cadastrar
                  </button>
                </form>
              </div>
            </div>
            <div className=" col-sm col-lg-6">
              {entregador.map((man) => (
                <div className="cardEntregador">
                  <h5>{man.name}</h5>
                  <p>{man.email}</p>
                  <p>{man.telephone}</p>
                  <button type="button" onClick={() => deleteEntregador(man._id)} class="btn btn-danger">
                    Deletar
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
